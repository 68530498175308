import { default as _91id_93_45_91slug_93GicoGZzncIMeta } from "/app/src/pages/articles/[id]-[slug].vue?macro=true";
import { default as index22HbZ9SXNhMeta } from "/app/src/pages/articles/index.vue?macro=true";
import { default as _91category_93_45_91slug_933PI1pBkBIxMeta } from "/app/src/pages/catalog/[category]-[slug].vue?macro=true";
import { default as cookie_45policyWrqZjqr6gMMeta } from "/app/src/pages/cookie-policy.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91product_93_45_91slug_93SFEAr6XsN3Meta } from "/app/src/pages/product/[product]-[slug].vue?macro=true";
import { default as bonusesNwXnstYbQHMeta } from "/app/src/pages/profile/bonuses.vue?macro=true";
import { default as incompletepVbXbLiTn0Meta } from "/app/src/pages/profile/incomplete.vue?macro=true";
import { default as indexSJNeMkG0qzMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as stickersBfe1RcMBHOMeta } from "/app/src/pages/profile/stickers.vue?macro=true";
import { default as _91category_93_45_91categorySlug_93mL22tkeR9QMeta } from "/app/src/pages/promo-catalog/[category]-[categorySlug].vue?macro=true";
import { default as _91product_93_45_91slug_93iUrEniHiPTMeta } from "/app/src/pages/promo-product/[product]-[slug].vue?macro=true";
import { default as cardfAzS7zvV5UMeta } from "/app/src/pages/registration/card.vue?macro=true";
import { default as indexNbUvxHm6A3Meta } from "/app/src/pages/registration/index.vue?macro=true";
import { default as index2gRhQT9qIDMeta } from "/app/src/pages/search/index.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "articles-id-slug",
    path: "/articles/:id()-:slug()",
    component: () => import("/app/src/pages/articles/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/app/src/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-category-slug",
    path: "/catalog/:category()-:slug()",
    component: () => import("/app/src/pages/catalog/[category]-[slug].vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/app/src/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "product-product-slug",
    path: "/product/:product()-:slug()",
    component: () => import("/app/src/pages/product/[product]-[slug].vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    component: () => import("/app/src/pages/profile/bonuses.vue").then(m => m.default || m)
  },
  {
    name: "profile-incomplete",
    path: "/profile/incomplete",
    meta: incompletepVbXbLiTn0Meta || {},
    component: () => import("/app/src/pages/profile/incomplete.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-stickers",
    path: "/profile/stickers",
    component: () => import("/app/src/pages/profile/stickers.vue").then(m => m.default || m)
  },
  {
    name: "promo-catalog-category-categorySlug",
    path: "/promo-catalog/:category()-:categorySlug()",
    component: () => import("/app/src/pages/promo-catalog/[category]-[categorySlug].vue").then(m => m.default || m)
  },
  {
    name: "promo-product-product-slug",
    path: "/promo-product/:product()-:slug()",
    component: () => import("/app/src/pages/promo-product/[product]-[slug].vue").then(m => m.default || m)
  },
  {
    name: "registration-card",
    path: "/registration/card",
    meta: cardfAzS7zvV5UMeta || {},
    component: () => import("/app/src/pages/registration/card.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    meta: indexNbUvxHm6A3Meta || {},
    component: () => import("/app/src/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/app/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/catalog-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/promo-catalog-sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]